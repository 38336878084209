/**
 * @generated SignedSource<<6a57ed9f58b1d503df51d7349edf37f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaylistByIdQuery$variables = {
  id: string;
};
export type PlaylistByIdQuery$data = {
  readonly playlist: {
    readonly " $fragmentSpreads": FragmentRefs<"PlaylistRouteData">;
  } | null | undefined;
  readonly rootRealm: {
    readonly " $fragmentSpreads": FragmentRefs<"NavigationData">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"UserData">;
};
export type PlaylistByIdQuery = {
  response: PlaylistByIdQuery$data;
  variables: PlaylistByIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dummy",
    "storageKey": null
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "NotAllowed",
  "abstractKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlaylistByIdQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserData"
      },
      {
        "alias": "playlist",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "playlistById",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PlaylistRouteData"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Realm",
        "kind": "LinkedField",
        "name": "rootRealm",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NavigationData"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlaylistByIdQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUpload",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUseStudio",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUseEditor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canCreateUserRealm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canFindUnlisted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userRole",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "playlist",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "playlistById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isPlaylist"
          },
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "entries",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "created",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "creators",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isLive",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Series",
                        "kind": "LinkedField",
                        "name": "series",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SyncedEventData",
                        "kind": "LinkedField",
                        "name": "syncedData",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "duration",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbnail",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Track",
                            "kind": "LinkedField",
                            "name": "tracks",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "resolution",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "AuthorizedEvent",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v3/*: any*/),
                    "type": "Missing",
                    "abstractKey": null
                  },
                  (v4/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "AuthorizedPlaylist",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Realm",
        "kind": "LinkedField",
        "name": "rootRealm",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "childOrder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isUserRoot",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Realm",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Realm",
            "kind": "LinkedField",
            "name": "parent",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isMainRoot",
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "101704ea31308a65235b4e14323c8ad3",
    "id": null,
    "metadata": {},
    "name": "PlaylistByIdQuery",
    "operationKind": "query",
    "text": "query PlaylistByIdQuery(\n  $id: ID!\n) {\n  ...UserData\n  playlist: playlistById(id: $id) {\n    __typename\n    ...PlaylistRouteData\n    ... on AuthorizedPlaylist {\n      id\n    }\n  }\n  rootRealm {\n    ...NavigationData\n    id\n  }\n}\n\nfragment NavigationData on Realm {\n  id\n  name\n  childOrder\n  isUserRoot\n  children {\n    id\n    name\n    path\n  }\n  parent {\n    isMainRoot\n    name\n    path\n    id\n  }\n}\n\nfragment PlaylistBlockPlaylistData on Playlist {\n  __isPlaylist: __typename\n  __typename\n  ... on NotAllowed {\n    dummy\n  }\n  ... on AuthorizedPlaylist {\n    id\n    title\n    description\n    entries {\n      __typename\n      ... on AuthorizedEvent {\n        id\n        ...VideoListEventData\n      }\n      ... on Missing {\n        dummy\n      }\n      ... on NotAllowed {\n        dummy\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n\nfragment PlaylistRouteData on Playlist {\n  __isPlaylist: __typename\n  __typename\n  ... on NotAllowed {\n    dummy\n  }\n  ... on AuthorizedPlaylist {\n    id\n    title\n    description\n    entries {\n      __typename\n      ... on AuthorizedEvent {\n        id\n        ...VideoListEventData\n      }\n      ... on Missing {\n        dummy\n      }\n      ... on NotAllowed {\n        dummy\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n  ...PlaylistBlockPlaylistData\n}\n\nfragment UserData on Query {\n  currentUser {\n    username\n    displayName\n    canUpload\n    canUseStudio\n    canUseEditor\n    canCreateUserRealm\n    canFindUnlisted\n    roles\n    userRole\n  }\n}\n\nfragment VideoListEventData on AuthorizedEvent {\n  id\n  title\n  created\n  creators\n  isLive\n  description\n  series {\n    title\n    id\n  }\n  syncedData {\n    duration\n    thumbnail\n    startTime\n    endTime\n    tracks {\n      resolution\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "82001ce96bcc8621d4ee40e4e119659c";

export default node;
