/**
 * @generated SignedSource<<586feb1aa515fa355ea4f91e5d7f80d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AccessUserSearchQuery$variables = {
  q: string;
};
export type AccessUserSearchQuery$data = {
  readonly users: {
    readonly items?: ReadonlyArray<{
      readonly displayName: string;
      readonly userRole: string;
    }>;
  };
};
export type AccessUserSearchQuery = {
  response: AccessUserSearchQuery$data;
  variables: AccessUserSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "q"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "q"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "KnownUser",
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userRole",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "KnownUserSearchResults",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccessUserSearchQuery",
    "selections": [
      {
        "alias": "users",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "searchKnownUsers",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccessUserSearchQuery",
    "selections": [
      {
        "alias": "users",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "searchKnownUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e17c526201266163c406d705d6efd265",
    "id": null,
    "metadata": {},
    "name": "AccessUserSearchQuery",
    "operationKind": "query",
    "text": "query AccessUserSearchQuery(\n  $q: String!\n) {\n  users: searchKnownUsers(query: $q) {\n    __typename\n    ... on KnownUserSearchResults {\n      items {\n        displayName\n        userRole\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d3acdaeff0fcca5339ca211aadbbf02";

export default node;
