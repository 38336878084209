/**
 * @generated SignedSource<<4e88adc4ef92390b6638cb338afedfe7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VideoListLayout = "GALLERY" | "LIST" | "SLIDER" | "%future added value";
export type VideoListOrder = "AZ" | "NEW_TO_OLD" | "OLD_TO_NEW" | "ORIGINAL" | "ZA" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PlaylistBlockData$data = {
  readonly layout: VideoListLayout;
  readonly order: VideoListOrder;
  readonly playlist: {
    readonly " $fragmentSpreads": FragmentRefs<"PlaylistBlockPlaylistData">;
  } | null | undefined;
  readonly showMetadata: boolean;
  readonly showTitle: boolean;
  readonly " $fragmentType": "PlaylistBlockData";
};
export type PlaylistBlockData$key = {
  readonly " $data"?: PlaylistBlockData$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlaylistBlockData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaylistBlockData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "playlist",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PlaylistBlockPlaylistData"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showMetadata",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "order",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "layout",
      "storageKey": null
    }
  ],
  "type": "PlaylistBlock",
  "abstractKey": null
};

(node as any).hash = "f4b2ac204969c759441772073425faeb";

export default node;
