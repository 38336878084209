/**
 * @generated SignedSource<<22c1c1227d052a8e641ba6e8a94fd227>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VideoListLayout = "GALLERY" | "LIST" | "SLIDER" | "%future added value";
export type VideoListOrder = "AZ" | "NEW_TO_OLD" | "OLD_TO_NEW" | "ORIGINAL" | "ZA" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PlaylistEditModeBlockData$data = {
  readonly layout: VideoListLayout;
  readonly order: VideoListOrder;
  readonly playlist: {
    readonly __typename: "AuthorizedPlaylist";
    readonly description: string | null | undefined;
    readonly id: string;
    readonly opencastId: string;
    readonly title: string;
  } | {
    readonly __typename: "NotAllowed";
    readonly dummy: boolean | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly showMetadata: boolean;
  readonly showTitle: boolean;
  readonly " $fragmentType": "PlaylistEditModeBlockData";
};
export type PlaylistEditModeBlockData$key = {
  readonly " $data"?: PlaylistEditModeBlockData$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlaylistEditModeBlockData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaylistEditModeBlockData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "playlist",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dummy",
              "storageKey": null
            }
          ],
          "type": "NotAllowed",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "opencastId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "type": "AuthorizedPlaylist",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showMetadata",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "order",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "layout",
      "storageKey": null
    }
  ],
  "type": "PlaylistBlock",
  "abstractKey": null
};

(node as any).hash = "abe9a596110e7dd067a666ce8e9470e7";

export default node;
