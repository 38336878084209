/**
 * @generated SignedSource<<a0906d222b0f430f2276156728f3bd48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RealmCreateForUserMutation$variables = Record<PropertyKey, never>;
export type RealmCreateForUserMutation$data = {
  readonly createMyUserRealm: {
    readonly id: string;
    readonly path: string;
  };
};
export type RealmCreateForUserMutation = {
  response: RealmCreateForUserMutation$data;
  variables: RealmCreateForUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Realm",
    "kind": "LinkedField",
    "name": "createMyUserRealm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "path",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RealmCreateForUserMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RealmCreateForUserMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "19bc125bacf9d9f0aed6e29f33313022",
    "id": null,
    "metadata": {},
    "name": "RealmCreateForUserMutation",
    "operationKind": "mutation",
    "text": "mutation RealmCreateForUserMutation {\n  createMyUserRealm {\n    id\n    path\n  }\n}\n"
  }
};
})();

(node as any).hash = "125da117209d1d26dc7a0a74f2581d92";

export default node;
