/**
 * @generated SignedSource<<febbd245cbaec635b0aae92adc37d134>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaylistRouteData$data = {
  readonly __typename: string;
  readonly description?: string | null | undefined;
  readonly dummy?: boolean | null | undefined;
  readonly entries?: ReadonlyArray<{
    readonly __typename: "AuthorizedEvent";
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"VideoListEventData">;
  } | {
    readonly __typename: "Missing";
    readonly dummy: boolean | null | undefined;
  } | {
    readonly __typename: "NotAllowed";
    readonly dummy: boolean | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  }>;
  readonly id?: string;
  readonly title?: string;
  readonly " $fragmentSpreads": FragmentRefs<"PlaylistBlockPlaylistData">;
  readonly " $fragmentType": "PlaylistRouteData";
};
export type PlaylistRouteData$key = {
  readonly " $data"?: PlaylistRouteData$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlaylistRouteData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dummy",
    "storageKey": null
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": (v1/*: any*/),
  "type": "NotAllowed",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlaylistRouteData",
  "selections": [
    (v0/*: any*/),
    (v2/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "entries",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v3/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "VideoListEventData",
                  "selections": [
                    (v3/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "created",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "creators",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isLive",
                      "storageKey": null
                    },
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Series",
                      "kind": "LinkedField",
                      "name": "series",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SyncedEventData",
                      "kind": "LinkedField",
                      "name": "syncedData",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "duration",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "thumbnail",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "startTime",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "endTime",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Track",
                          "kind": "LinkedField",
                          "name": "tracks",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "resolution",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "type": "AuthorizedEvent",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "Missing",
              "abstractKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "AuthorizedPlaylist",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlaylistBlockPlaylistData"
    }
  ],
  "type": "Playlist",
  "abstractKey": "__isPlaylist"
};
})();

(node as any).hash = "93e709d31dfb5a272c191a78cc786288";

export default node;
