/**
 * @generated SignedSource<<5c96e682bb231fc7a4363bbca9653d21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditButtonsRealmData$data = {
  readonly blocks: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"RemoveButtonData">;
  }>;
  readonly nameSource: {
    readonly block?: {
      readonly id: string;
    };
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"MoveButtonsData">;
  readonly " $fragmentType": "EditButtonsRealmData";
};
export type EditButtonsRealmData$key = {
  readonly " $data"?: EditButtonsRealmData$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditButtonsRealmData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditButtonsRealmData",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MoveButtonsData"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "blocks",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RemoveButtonData"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "nameSource",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "block",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RealmNameFromBlock",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Realm",
  "abstractKey": null
};

(node as any).hash = "0bfed75f2aff3dea5aada4b9e61ba001";

export default node;
