/**
 * @generated SignedSource<<80f1d355f80ac9c210830a4a596bbe64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlocksBlockData$data = {
  readonly __typename: string;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"PlaylistBlockData" | "SeriesBlockData" | "TextBlockData" | "TitleBlockData" | "VideoBlockData">;
  readonly " $fragmentType": "BlocksBlockData";
};
export type BlocksBlockData$key = {
  readonly " $data"?: BlocksBlockData$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlocksBlockData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlocksBlockData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TitleBlockData"
        }
      ],
      "type": "TitleBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TextBlockData"
        }
      ],
      "type": "TextBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SeriesBlockData"
        }
      ],
      "type": "SeriesBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VideoBlockData"
        }
      ],
      "type": "VideoBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PlaylistBlockData"
        }
      ],
      "type": "PlaylistBlock",
      "abstractKey": null
    }
  ],
  "type": "Block",
  "abstractKey": "__isBlock"
};

(node as any).hash = "1404f2f76b3acf75c16669e4fa45b4b2";

export default node;
