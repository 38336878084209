/**
 * @generated SignedSource<<3182bb0adc4e3d5e7d5f8ecfc5496a3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveButtonData$data = {
  readonly id: string;
  readonly " $fragmentType": "RemoveButtonData";
};
export type RemoveButtonData$key = {
  readonly " $data"?: RemoveButtonData$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoveButtonData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoveButtonData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Block",
  "abstractKey": "__isBlock"
};

(node as any).hash = "cfffa7c33d9f702943ca017319eabb55";

export default node;
