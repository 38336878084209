/**
 * @generated SignedSource<<e36260ef61161e2374ca92297c519e7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeneralRealmData$data = {
  readonly blocks: ReadonlyArray<{
    readonly event?: {
      readonly title?: string;
    } | null | undefined;
    readonly id: string;
    readonly series?: {
      readonly title: string;
    } | null | undefined;
  }>;
  readonly id: string;
  readonly isMainRoot: boolean;
  readonly name: string | null | undefined;
  readonly nameSource: {
    readonly __typename: "PlainRealmName";
    readonly name: string;
  } | {
    readonly __typename: "RealmNameFromBlock";
    readonly block: {
      readonly id: string;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly " $fragmentType": "GeneralRealmData";
};
export type GeneralRealmData$key = {
  readonly " $data"?: GeneralRealmData$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeneralRealmData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeneralRealmData",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "nameSource",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/)
          ],
          "type": "PlainRealmName",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "block",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "RealmNameFromBlock",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "blocks",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "event",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": (v2/*: any*/),
                  "type": "AuthorizedEvent",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "VideoBlock",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Series",
              "kind": "LinkedField",
              "name": "series",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "type": "SeriesBlock",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMainRoot",
      "storageKey": null
    }
  ],
  "type": "Realm",
  "abstractKey": null
};
})();

(node as any).hash = "e1102b3a8d10b506063fd1d67efc3cb3";

export default node;
