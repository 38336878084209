/**
 * @generated SignedSource<<1f022573b3ffbccd1657ca9022689379>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TextBlockData$data = {
  readonly content: string;
  readonly " $fragmentType": "TextBlockData";
};
export type TextBlockData$key = {
  readonly " $data"?: TextBlockData$data;
  readonly " $fragmentSpreads": FragmentRefs<"TextBlockData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TextBlockData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    }
  ],
  "type": "TextBlock",
  "abstractKey": null
};

(node as any).hash = "5f5f8eacc5e08c413629433f0ae355d3";

export default node;
