/**
 * @generated SignedSource<<35a852f64cd9c9c54413dfde4665156a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditBlockUpdateRealmNameData$data = {
  readonly realm: {
    readonly name: string | null | undefined;
    readonly nameSource: {
      readonly block?: {
        readonly id: string;
      };
    } | null | undefined;
  };
  readonly " $fragmentType": "EditBlockUpdateRealmNameData";
};
export type EditBlockUpdateRealmNameData$key = {
  readonly " $data"?: EditBlockUpdateRealmNameData$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditBlockUpdateRealmNameData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditBlockUpdateRealmNameData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Realm",
      "kind": "LinkedField",
      "name": "realm",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "nameSource",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "block",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "RealmNameFromBlock",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Block",
  "abstractKey": "__isBlock"
};

(node as any).hash = "5a57c8f5f2f4a4491a2aa6b4f83bf48d";

export default node;
