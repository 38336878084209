/**
 * @generated SignedSource<<7c13b9577a692108800ba11491adfd0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedVideoManageQuery$variables = {
  id: string;
};
export type SharedVideoManageQuery$data = {
  readonly event: {
    readonly __typename: "AuthorizedEvent";
    readonly acl: ReadonlyArray<{
      readonly actions: ReadonlyArray<string>;
      readonly info: {
        readonly implies: ReadonlyArray<string> | null | undefined;
        readonly label: Record<string, string>;
        readonly large: boolean;
      } | null | undefined;
      readonly role: string;
    }>;
    readonly canWrite: boolean;
    readonly created: string;
    readonly description: string | null | undefined;
    readonly hostRealms: ReadonlyArray<{
      readonly id: string;
      readonly isMainRoot: boolean;
      readonly name: string | null | undefined;
      readonly path: string;
    }>;
    readonly id: string;
    readonly isLive: boolean;
    readonly opencastId: string;
    readonly series: {
      readonly opencastId: string;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"SeriesBlockSeriesData">;
    } | null | undefined;
    readonly syncedData: {
      readonly duration: number;
      readonly endTime: string | null | undefined;
      readonly startTime: string | null | undefined;
      readonly thumbnail: string | null | undefined;
      readonly tracks: ReadonlyArray<{
        readonly flavor: string;
        readonly mimetype: string | null | undefined;
        readonly resolution: ReadonlyArray<number> | null | undefined;
        readonly uri: string;
      }>;
      readonly updated: string;
    } | null | undefined;
    readonly title: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"AccessKnownRolesData" | "UserData">;
};
export type SharedVideoManageQuery = {
  response: SharedVideoManageQuery$data;
  variables: SharedVideoManageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "opencastId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canWrite",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLive",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "implies",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "large",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "AclItem",
  "kind": "LinkedField",
  "name": "acl",
  "plural": true,
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleInfo",
      "kind": "LinkedField",
      "name": "info",
      "plural": false,
      "selections": [
        (v11/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endTime",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resolution",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "SyncedEventData",
  "kind": "LinkedField",
  "name": "syncedData",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    (v16/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updated",
      "storageKey": null
    },
    (v17/*: any*/),
    (v18/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Track",
      "kind": "LinkedField",
      "name": "tracks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "flavor",
          "storageKey": null
        },
        (v19/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mimetype",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uri",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "Realm",
  "kind": "LinkedField",
  "name": "hostRealms",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMainRoot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SharedVideoManageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserData"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AccessKnownRolesData"
      },
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "eventById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v14/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Series",
                "kind": "LinkedField",
                "name": "series",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SeriesBlockSeriesData"
                  }
                ],
                "storageKey": null
              },
              (v21/*: any*/)
            ],
            "type": "AuthorizedEvent",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SharedVideoManageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUpload",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUseStudio",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUseEditor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canCreateUserRealm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canFindUnlisted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userRole",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "KnownGroup",
        "kind": "LinkedField",
        "name": "knownGroups",
        "plural": true,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "eventById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v14/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Series",
                "kind": "LinkedField",
                "name": "series",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SyncedSeriesData",
                    "kind": "LinkedField",
                    "name": "syncedData",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "entries",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "creators",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Series",
                            "kind": "LinkedField",
                            "name": "series",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SyncedEventData",
                            "kind": "LinkedField",
                            "name": "syncedData",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Track",
                                "kind": "LinkedField",
                                "name": "tracks",
                                "plural": true,
                                "selections": [
                                  (v19/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "AuthorizedEvent",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dummy",
                            "storageKey": null
                          }
                        ],
                        "type": "NotAllowed",
                        "abstractKey": null
                      },
                      (v22/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v21/*: any*/)
            ],
            "type": "AuthorizedEvent",
            "abstractKey": null
          },
          (v22/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c3245a138eace05da8d27d091fc31a0",
    "id": null,
    "metadata": {},
    "name": "SharedVideoManageQuery",
    "operationKind": "query",
    "text": "query SharedVideoManageQuery(\n  $id: ID!\n) {\n  ...UserData\n  ...AccessKnownRolesData\n  event: eventById(id: $id) {\n    __typename\n    ... on AuthorizedEvent {\n      id\n      title\n      description\n      opencastId\n      created\n      canWrite\n      isLive\n      acl {\n        role\n        actions\n        info {\n          label\n          implies\n          large\n        }\n      }\n      syncedData {\n        duration\n        thumbnail\n        updated\n        startTime\n        endTime\n        tracks {\n          flavor\n          resolution\n          mimetype\n          uri\n        }\n      }\n      series {\n        title\n        opencastId\n        ...SeriesBlockSeriesData\n        id\n      }\n      hostRealms {\n        id\n        isMainRoot\n        name\n        path\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment AccessKnownRolesData on Query {\n  knownGroups {\n    role\n    label\n    implies\n    large\n  }\n}\n\nfragment SeriesBlockSeriesData on Series {\n  title\n  syncedData {\n    description\n  }\n  entries {\n    __typename\n    ... on AuthorizedEvent {\n      id\n      ...VideoListEventData\n    }\n    ... on NotAllowed {\n      dummy\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment UserData on Query {\n  currentUser {\n    username\n    displayName\n    canUpload\n    canUseStudio\n    canUseEditor\n    canCreateUserRealm\n    canFindUnlisted\n    roles\n    userRole\n  }\n}\n\nfragment VideoListEventData on AuthorizedEvent {\n  id\n  title\n  created\n  creators\n  isLive\n  description\n  series {\n    title\n    id\n  }\n  syncedData {\n    duration\n    thumbnail\n    startTime\n    endTime\n    tracks {\n      resolution\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b30404e492542235d02d34e836b4978f";

export default node;
