/**
 * @generated SignedSource<<9adad27d521280bea5d4c44a376a35a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DangerZoneRealmData$data = {
  readonly id: string;
  readonly isMainRoot: boolean;
  readonly isUserRoot: boolean;
  readonly name: string | null | undefined;
  readonly numberOfDescendants: number;
  readonly path: string;
  readonly " $fragmentType": "DangerZoneRealmData";
};
export type DangerZoneRealmData$key = {
  readonly " $data"?: DangerZoneRealmData$data;
  readonly " $fragmentSpreads": FragmentRefs<"DangerZoneRealmData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DangerZoneRealmData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMainRoot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUserRoot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfDescendants",
      "storageKey": null
    }
  ],
  "type": "Realm",
  "abstractKey": null
};

(node as any).hash = "ff66bf13fe7d58c04ac60600203f01dd";

export default node;
