/**
 * @generated SignedSource<<bb7b5bbbb3ac47b68ad7a84aeff56fd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessKnownRolesData$data = {
  readonly knownGroups: ReadonlyArray<{
    readonly implies: ReadonlyArray<string>;
    readonly label: Record<string, string>;
    readonly large: boolean;
    readonly role: string;
  }>;
  readonly " $fragmentType": "AccessKnownRolesData";
};
export type AccessKnownRolesData$key = {
  readonly " $data"?: AccessKnownRolesData$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessKnownRolesData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessKnownRolesData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "KnownGroup",
      "kind": "LinkedField",
      "name": "knownGroups",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "implies",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "large",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "cc39cd49164828210ab038e7ff5a7755";

export default node;
