/**
 * @generated SignedSource<<7dee4a2886aedb6024b0423df63ae002>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VideoListEventData$data = {
  readonly created: string;
  readonly creators: ReadonlyArray<string>;
  readonly description: string | null | undefined;
  readonly id: string;
  readonly isLive: boolean;
  readonly series: {
    readonly id: string;
    readonly title: string;
  } | null | undefined;
  readonly syncedData: {
    readonly duration: number;
    readonly endTime: string | null | undefined;
    readonly startTime: string | null | undefined;
    readonly thumbnail: string | null | undefined;
    readonly tracks: ReadonlyArray<{
      readonly resolution: ReadonlyArray<number> | null | undefined;
    }>;
  } | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "VideoListEventData";
};
export type VideoListEventData$key = {
  readonly " $data"?: VideoListEventData$data;
  readonly " $fragmentSpreads": FragmentRefs<"VideoListEventData">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "VideoListEventData"
};

(node as any).hash = "57e3ff212173ad8f5de0051e6b2b775f";

export default node;
