/**
 * @generated SignedSource<<894a6c8c1047b96283f724f7b89ffb04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddButtonsRealmData$data = {
  readonly id: string;
  readonly " $fragmentType": "AddButtonsRealmData";
};
export type AddButtonsRealmData$key = {
  readonly " $data"?: AddButtonsRealmData$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddButtonsRealmData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddButtonsRealmData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Realm",
  "abstractKey": null
};

(node as any).hash = "6abb70708a90fbf7e549c55135206fed";

export default node;
