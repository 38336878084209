/**
 * @generated SignedSource<<0613c3afb89e8e2a3693bbb340dcbf65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SearchableSelectSeriesQuery$variables = {
  q: string;
  writableOnly: boolean;
};
export type SearchableSelectSeriesQuery$data = {
  readonly series: {
    readonly items?: ReadonlyArray<{
      readonly description: string | null | undefined;
      readonly id: string;
      readonly opencastId: string;
      readonly title: string;
    }>;
  };
};
export type SearchableSelectSeriesQuery = {
  response: SearchableSelectSeriesQuery$data;
  variables: SearchableSelectSeriesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "q"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "writableOnly"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "q"
  },
  {
    "kind": "Variable",
    "name": "writableOnly",
    "variableName": "writableOnly"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchSeries",
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "opencastId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SeriesSearchResults",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchableSelectSeriesQuery",
    "selections": [
      {
        "alias": "series",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "searchAllSeries",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchableSelectSeriesQuery",
    "selections": [
      {
        "alias": "series",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "searchAllSeries",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f3070224f28f231774f26c79809539b3",
    "id": null,
    "metadata": {},
    "name": "SearchableSelectSeriesQuery",
    "operationKind": "query",
    "text": "query SearchableSelectSeriesQuery(\n  $q: String!\n  $writableOnly: Boolean!\n) {\n  series: searchAllSeries(query: $q, writableOnly: $writableOnly) {\n    __typename\n    ... on SeriesSearchResults {\n      items {\n        id\n        opencastId\n        title\n        description\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bb8f878dbd3d5c07b1b784a8002d24b4";

export default node;
