/**
 * @generated SignedSource<<d141ca60ea7f5dd018c0d27b21d31523>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewRealm = {
  name: string;
  parent: string;
  pathSegment: string;
};
export type AddChildMutation$variables = {
  realm: NewRealm;
};
export type AddChildMutation$data = {
  readonly addRealm: {
    readonly parent: {
      readonly " $fragmentSpreads": FragmentRefs<"NavigationData">;
    } | null | undefined;
    readonly path: string;
  };
};
export type AddChildMutation = {
  response: AddChildMutation$data;
  variables: AddChildMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "realm"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "realm",
    "variableName": "realm"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddChildMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Realm",
        "kind": "LinkedField",
        "name": "addRealm",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Realm",
            "kind": "LinkedField",
            "name": "parent",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NavigationData"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddChildMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Realm",
        "kind": "LinkedField",
        "name": "addRealm",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Realm",
            "kind": "LinkedField",
            "name": "parent",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "childOrder",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isUserRoot",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Realm",
                "kind": "LinkedField",
                "name": "children",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Realm",
                "kind": "LinkedField",
                "name": "parent",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isMainRoot",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "573d76aeae26055a265f86be53b447c5",
    "id": null,
    "metadata": {},
    "name": "AddChildMutation",
    "operationKind": "mutation",
    "text": "mutation AddChildMutation(\n  $realm: NewRealm!\n) {\n  addRealm(realm: $realm) {\n    path\n    parent {\n      ...NavigationData\n      id\n    }\n    id\n  }\n}\n\nfragment NavigationData on Realm {\n  id\n  name\n  childOrder\n  isUserRoot\n  children {\n    id\n    name\n    path\n  }\n  parent {\n    isMainRoot\n    name\n    path\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "88d9985567332167b0886ea0e6a7dcd5";

export default node;
