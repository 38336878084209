/**
 * @generated SignedSource<<8e7382335fa47321358b9a6e78b1563d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RealmOrder = "ALPHABETIC_ASC" | "ALPHABETIC_DESC" | "BY_INDEX" | "%future added value";
export type ChildIndex = {
  id: string;
  index: number;
};
export type ChildOrderMutation$variables = {
  indices?: ReadonlyArray<ChildIndex> | null | undefined;
  order: RealmOrder;
  parent: string;
};
export type ChildOrderMutation$data = {
  readonly setChildOrder: {
    readonly " $fragmentSpreads": FragmentRefs<"ChildOrderEditData">;
  };
};
export type ChildOrderMutation = {
  response: ChildOrderMutation$data;
  variables: ChildOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "indices"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parent"
},
v3 = [
  {
    "kind": "Variable",
    "name": "childIndices",
    "variableName": "indices"
  },
  {
    "kind": "Variable",
    "name": "childOrder",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "parent",
    "variableName": "parent"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChildOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Realm",
        "kind": "LinkedField",
        "name": "setChildOrder",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChildOrderEditData"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChildOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Realm",
        "kind": "LinkedField",
        "name": "setChildOrder",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "childOrder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Realm",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "index",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dab5a0ec102dab904826f26e10f7d8f6",
    "id": null,
    "metadata": {},
    "name": "ChildOrderMutation",
    "operationKind": "mutation",
    "text": "mutation ChildOrderMutation(\n  $parent: ID!\n  $order: RealmOrder!\n  $indices: [ChildIndex!]\n) {\n  setChildOrder(parent: $parent, childOrder: $order, childIndices: $indices) {\n    ...ChildOrderEditData\n    id\n  }\n}\n\nfragment ChildOrderEditData on Realm {\n  id\n  name\n  childOrder\n  children {\n    id\n    name\n    index\n  }\n}\n"
  }
};
})();

(node as any).hash = "2dec8d2535b85aec06f79a69a6434d7f";

export default node;
