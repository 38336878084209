/**
 * @generated SignedSource<<0b74743e339117004085ea0ba9f7c2cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RealmOrder = "ALPHABETIC_ASC" | "ALPHABETIC_DESC" | "BY_INDEX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ChildOrderEditData$data = {
  readonly childOrder: RealmOrder;
  readonly children: ReadonlyArray<{
    readonly id: string;
    readonly index: number;
    readonly name: string | null | undefined;
  }>;
  readonly id: string;
  readonly name: string | null | undefined;
  readonly " $fragmentType": "ChildOrderEditData";
};
export type ChildOrderEditData$key = {
  readonly " $data"?: ChildOrderEditData$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChildOrderEditData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChildOrderEditData",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "childOrder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Realm",
      "kind": "LinkedField",
      "name": "children",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "index",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Realm",
  "abstractKey": null
};
})();

(node as any).hash = "6a0ed2b7617b57263e8e1845cae91bfe";

export default node;
