/**
 * @generated SignedSource<<7bf6c7b751409de4f05b95211fdafa0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotFoundQuery$variables = Record<PropertyKey, never>;
export type NotFoundQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserData">;
};
export type NotFoundQuery = {
  response: NotFoundQuery$data;
  variables: NotFoundQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotFoundQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserData"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotFoundQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUpload",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUseStudio",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUseEditor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canCreateUserRealm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canFindUnlisted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userRole",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "30e153aa7542f2430f5f1487a926f85a",
    "id": null,
    "metadata": {},
    "name": "NotFoundQuery",
    "operationKind": "query",
    "text": "query NotFoundQuery {\n  ...UserData\n}\n\nfragment UserData on Query {\n  currentUser {\n    username\n    displayName\n    canUpload\n    canUseStudio\n    canUseEditor\n    canCreateUserRealm\n    canFindUnlisted\n    roles\n    userRole\n  }\n}\n"
  }
};

(node as any).hash = "c8372b9ff304a11a06c19ee08f6507f9";

export default node;
