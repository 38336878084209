/**
 * @generated SignedSource<<fb2ff118e5673c5fe8c97516312ca5bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type JwtService = "EDITOR" | "STUDIO" | "UPLOAD" | "%future added value";
export type authJwtQuery$variables = {
  service: JwtService;
};
export type authJwtQuery$data = {
  readonly jwt: string;
};
export type authJwtQuery = {
  response: authJwtQuery$data;
  variables: authJwtQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "service"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "service",
        "variableName": "service"
      }
    ],
    "kind": "ScalarField",
    "name": "jwt",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authJwtQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authJwtQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0d23380d92c2c62e2e9cf62d19b39be9",
    "id": null,
    "metadata": {},
    "name": "authJwtQuery",
    "operationKind": "query",
    "text": "query authJwtQuery(\n  $service: JwtService!\n) {\n  jwt(service: $service)\n}\n"
  }
};
})();

(node as any).hash = "c190dfaefa4f570a079eab2d138ccd2e";

export default node;
