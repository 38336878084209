/**
 * @generated SignedSource<<706fd39013db810ad680eea85fce4cbc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VideoPageRealmData$data = {
  readonly ancestors: ReadonlyArray<{
    readonly name: string | null | undefined;
    readonly path: string;
  }>;
  readonly isMainRoot: boolean;
  readonly name: string | null | undefined;
  readonly path: string;
  readonly " $fragmentType": "VideoPageRealmData";
};
export type VideoPageRealmData$key = {
  readonly " $data"?: VideoPageRealmData$data;
  readonly " $fragmentSpreads": FragmentRefs<"VideoPageRealmData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VideoPageRealmData",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMainRoot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Realm",
      "kind": "LinkedField",
      "name": "ancestors",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Realm",
  "abstractKey": null
};
})();

(node as any).hash = "c9523996b24cd4a83baceda25d2351a3";

export default node;
