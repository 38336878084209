/**
 * @generated SignedSource<<ef1e1fdc2cff27f1970dc8dc8c0cb218>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RealmPermissionsData$data = {
  readonly ancestors: ReadonlyArray<{
    readonly ownerDisplayName: string | null | undefined;
  }>;
  readonly id: string;
  readonly inheritedAcl: ReadonlyArray<{
    readonly actions: ReadonlyArray<string>;
    readonly info: {
      readonly implies: ReadonlyArray<string> | null | undefined;
      readonly label: Record<string, string>;
      readonly large: boolean;
    } | null | undefined;
    readonly role: string;
  }>;
  readonly ownAcl: ReadonlyArray<{
    readonly actions: ReadonlyArray<string>;
    readonly info: {
      readonly implies: ReadonlyArray<string> | null | undefined;
      readonly label: Record<string, string>;
      readonly large: boolean;
    } | null | undefined;
    readonly role: string;
  }>;
  readonly ownerDisplayName: string | null | undefined;
  readonly " $fragmentType": "RealmPermissionsData";
};
export type RealmPermissionsData$key = {
  readonly " $data"?: RealmPermissionsData$data;
  readonly " $fragmentSpreads": FragmentRefs<"RealmPermissionsData">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "role",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "actions",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "RoleInfo",
    "kind": "LinkedField",
    "name": "info",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "implies",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "large",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ownerDisplayName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RealmPermissionsData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AclItem",
      "kind": "LinkedField",
      "name": "ownAcl",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AclItem",
      "kind": "LinkedField",
      "name": "inheritedAcl",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Realm",
      "kind": "LinkedField",
      "name": "ancestors",
      "plural": true,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Realm",
  "abstractKey": null
};
})();

(node as any).hash = "fa086ae8febb95394306718b4ac2fbdd";

export default node;
