/**
 * @generated SignedSource<<3924fdb18e6b00a61d200a055618e1d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateRealm = {
  parent?: string | null | undefined;
  pathSegment?: string | null | undefined;
};
export type DangerZoneChangePathMutation$variables = {
  id: string;
  set: UpdateRealm;
};
export type DangerZoneChangePathMutation$data = {
  readonly updateRealm: {
    readonly " $fragmentSpreads": FragmentRefs<"DangerZoneRealmData">;
  };
};
export type DangerZoneChangePathMutation = {
  response: DangerZoneChangePathMutation$data;
  variables: DangerZoneChangePathMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "set"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "set",
    "variableName": "set"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DangerZoneChangePathMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Realm",
        "kind": "LinkedField",
        "name": "updateRealm",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DangerZoneRealmData"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DangerZoneChangePathMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Realm",
        "kind": "LinkedField",
        "name": "updateRealm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isMainRoot",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isUserRoot",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "path",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numberOfDescendants",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c1cd0c644b0850fe117f305e80884bd8",
    "id": null,
    "metadata": {},
    "name": "DangerZoneChangePathMutation",
    "operationKind": "mutation",
    "text": "mutation DangerZoneChangePathMutation(\n  $id: ID!\n  $set: UpdateRealm!\n) {\n  updateRealm(id: $id, set: $set) {\n    ...DangerZoneRealmData\n    id\n  }\n}\n\nfragment DangerZoneRealmData on Realm {\n  id\n  name\n  isMainRoot\n  isUserRoot\n  path\n  numberOfDescendants\n}\n"
  }
};
})();

(node as any).hash = "e507aaa70b778bdf9716834384c2ce0d";

export default node;
