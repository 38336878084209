/**
 * @generated SignedSource<<e0e608d7bae58b859b8ea4cdeca55fb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmbedEventData$data = {
  readonly __typename: "AuthorizedEvent";
  readonly created: string;
  readonly creators: ReadonlyArray<string>;
  readonly description: string | null | undefined;
  readonly isLive: boolean;
  readonly metadata: Record<string, Record<string, string[]>>;
  readonly opencastId: string;
  readonly series: {
    readonly opencastId: string;
    readonly title: string;
  } | null | undefined;
  readonly syncedData: {
    readonly captions: ReadonlyArray<{
      readonly lang: string | null | undefined;
      readonly uri: string;
    }>;
    readonly duration: number;
    readonly endTime: string | null | undefined;
    readonly segments: ReadonlyArray<{
      readonly startTime: number;
      readonly uri: string;
    }>;
    readonly startTime: string | null | undefined;
    readonly thumbnail: string | null | undefined;
    readonly tracks: ReadonlyArray<{
      readonly flavor: string;
      readonly isMaster: boolean | null | undefined;
      readonly mimetype: string | null | undefined;
      readonly resolution: ReadonlyArray<number> | null | undefined;
      readonly uri: string;
    }>;
    readonly updated: string;
  } | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "EmbedEventData";
} | {
  readonly __typename: "NotAllowed";
  readonly dummy: boolean | null | undefined;
  readonly " $fragmentType": "EmbedEventData";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "EmbedEventData";
};
export type EmbedEventData$key = {
  readonly " $data"?: EmbedEventData$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmbedEventData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "opencastId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uri",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmbedEventData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dummy",
          "storageKey": null
        }
      ],
      "type": "NotAllowed",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "created",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isLive",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creators",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metadata",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Series",
          "kind": "LinkedField",
          "name": "series",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SyncedEventData",
          "kind": "LinkedField",
          "name": "syncedData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "updated",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "duration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumbnail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Track",
              "kind": "LinkedField",
              "name": "tracks",
              "plural": true,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "flavor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mimetype",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "resolution",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isMaster",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Caption",
              "kind": "LinkedField",
              "name": "captions",
              "plural": true,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lang",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Segment",
              "kind": "LinkedField",
              "name": "segments",
              "plural": true,
              "selections": [
                (v3/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "AuthorizedEvent",
      "abstractKey": null
    }
  ],
  "type": "Event",
  "abstractKey": "__isEvent"
};
})();

(node as any).hash = "a808b0ee7a86bc2932bff1a777c0ea78";

export default node;
