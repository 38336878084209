/**
 * @generated SignedSource<<6e5a6844c239a1fbc0af0cdeeca4103d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditModeFormRealmData$data = {
  readonly blocks: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly id: string;
  readonly " $fragmentType": "EditModeFormRealmData";
};
export type EditModeFormRealmData$key = {
  readonly " $data"?: EditModeFormRealmData$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditModeFormRealmData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditModeFormRealmData",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "blocks",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Realm",
  "abstractKey": null
};
})();

(node as any).hash = "c0c6eaacf988e66c16e1c5e1946fc4e0";

export default node;
