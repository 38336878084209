/**
 * @generated SignedSource<<ef3a1fac61106a2e195713ad625c7a1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TextEditModeBlockData$data = {
  readonly content: string;
  readonly " $fragmentType": "TextEditModeBlockData";
};
export type TextEditModeBlockData$key = {
  readonly " $data"?: TextEditModeBlockData$data;
  readonly " $fragmentSpreads": FragmentRefs<"TextEditModeBlockData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TextEditModeBlockData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    }
  ],
  "type": "TextBlock",
  "abstractKey": null
};

(node as any).hash = "5ff141267d554888a1074790ab4db66d";

export default node;
