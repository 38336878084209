/**
 * @generated SignedSource<<bb570a5f71c1aac03103647810bf7d63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RealmOrder = "ALPHABETIC_ASC" | "ALPHABETIC_DESC" | "BY_INDEX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NavigationData$data = {
  readonly childOrder: RealmOrder;
  readonly children: ReadonlyArray<{
    readonly id: string;
    readonly name: string | null | undefined;
    readonly path: string;
  }>;
  readonly id: string;
  readonly isUserRoot: boolean;
  readonly name: string | null | undefined;
  readonly parent: {
    readonly isMainRoot: boolean;
    readonly name: string | null | undefined;
    readonly path: string;
  } | null | undefined;
  readonly " $fragmentType": "NavigationData";
};
export type NavigationData$key = {
  readonly " $data"?: NavigationData$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavigationData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavigationData",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "childOrder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUserRoot",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Realm",
      "kind": "LinkedField",
      "name": "children",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Realm",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isMainRoot",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Realm",
  "abstractKey": null
};
})();

(node as any).hash = "ae4f15ab8282bfec4377175ab4b7a876";

export default node;
