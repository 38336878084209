/**
 * @generated SignedSource<<dd4de1121dbb865472f5af0b7282f2fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SearchableSelectPlaylistsQuery$variables = {
  q: string;
  writableOnly: boolean;
};
export type SearchableSelectPlaylistsQuery$data = {
  readonly playlists: {
    readonly items?: ReadonlyArray<{
      readonly description: string | null | undefined;
      readonly id: string;
      readonly opencastId: string;
      readonly title: string;
    }>;
  };
};
export type SearchableSelectPlaylistsQuery = {
  response: SearchableSelectPlaylistsQuery$data;
  variables: SearchableSelectPlaylistsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "q"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "writableOnly"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "q"
  },
  {
    "kind": "Variable",
    "name": "writableOnly",
    "variableName": "writableOnly"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchPlaylist",
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "opencastId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PlaylistSearchResults",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchableSelectPlaylistsQuery",
    "selections": [
      {
        "alias": "playlists",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "searchAllPlaylists",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchableSelectPlaylistsQuery",
    "selections": [
      {
        "alias": "playlists",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "searchAllPlaylists",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c7123da31d985c98aaf00a2f60c22fe3",
    "id": null,
    "metadata": {},
    "name": "SearchableSelectPlaylistsQuery",
    "operationKind": "query",
    "text": "query SearchableSelectPlaylistsQuery(\n  $q: String!\n  $writableOnly: Boolean!\n) {\n  playlists: searchAllPlaylists(query: $q, writableOnly: $writableOnly) {\n    __typename\n    ... on PlaylistSearchResults {\n      items {\n        id\n        opencastId\n        title\n        description\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd4ca03d70426bb30c23ae5064d43ca8";

export default node;
