/**
 * @generated SignedSource<<15885989568ebd8c3b787be73c343032>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdatedPermissions = {
  adminRoles?: ReadonlyArray<string> | null | undefined;
  moderatorRoles?: ReadonlyArray<string> | null | undefined;
};
export type RealmPermissionsMutation$variables = {
  id: string;
  permissions: UpdatedPermissions;
};
export type RealmPermissionsMutation$data = {
  readonly updatePermissions: {
    readonly canCurrentUserModerate: boolean;
    readonly isCurrentUserPageAdmin: boolean;
    readonly ownAcl: ReadonlyArray<{
      readonly actions: ReadonlyArray<string>;
      readonly info: {
        readonly implies: ReadonlyArray<string> | null | undefined;
        readonly label: Record<string, string>;
        readonly large: boolean;
      } | null | undefined;
      readonly role: string;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"GeneralRealmData">;
  };
};
export type RealmPermissionsMutation = {
  response: RealmPermissionsMutation$data;
  variables: RealmPermissionsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "permissions"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "permissions",
    "variableName": "permissions"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AclItem",
  "kind": "LinkedField",
  "name": "ownAcl",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleInfo",
      "kind": "LinkedField",
      "name": "info",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "implies",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "large",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCurrentUserPageAdmin",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canCurrentUserModerate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RealmPermissionsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Realm",
        "kind": "LinkedField",
        "name": "updatePermissions",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GeneralRealmData"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RealmPermissionsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Realm",
        "kind": "LinkedField",
        "name": "updatePermissions",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "nameSource",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/)
                ],
                "type": "PlainRealmName",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "block",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "RealmNameFromBlock",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "blocks",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "event",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v8/*: any*/)
                        ],
                        "type": "AuthorizedEvent",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "VideoBlock",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Series",
                    "kind": "LinkedField",
                    "name": "series",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "SeriesBlock",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isMainRoot",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "60b4c1b8d962f8f783f53c427051c08e",
    "id": null,
    "metadata": {},
    "name": "RealmPermissionsMutation",
    "operationKind": "mutation",
    "text": "mutation RealmPermissionsMutation(\n  $id: ID!\n  $permissions: UpdatedPermissions!\n) {\n  updatePermissions(id: $id, permissions: $permissions) {\n    ownAcl {\n      role\n      actions\n      info {\n        label\n        implies\n        large\n      }\n    }\n    isCurrentUserPageAdmin\n    canCurrentUserModerate\n    ...GeneralRealmData\n    id\n  }\n}\n\nfragment GeneralRealmData on Realm {\n  id\n  name\n  nameSource {\n    __typename\n    ... on PlainRealmName {\n      name\n    }\n    ... on RealmNameFromBlock {\n      block {\n        __typename\n        id\n      }\n    }\n  }\n  blocks {\n    __typename\n    id\n    ... on VideoBlock {\n      event {\n        __typename\n        ... on AuthorizedEvent {\n          title\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n    ... on SeriesBlock {\n      series {\n        title\n        id\n      }\n    }\n  }\n  isMainRoot\n}\n"
  }
};
})();

(node as any).hash = "eab0a482dfa347939ef61f5669fcfaa6";

export default node;
