/**
 * @generated SignedSource<<7dd91aa6beb15088f72ffa5df7131007>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type VideoEditModeSearchQuery$variables = {
  q: string;
};
export type VideoEditModeSearchQuery$data = {
  readonly events: {
    readonly items?: ReadonlyArray<{
      readonly created: string;
      readonly creators: ReadonlyArray<string>;
      readonly duration: number;
      readonly endTime: string | null | undefined;
      readonly id: string;
      readonly isLive: boolean;
      readonly seriesTitle: string | null | undefined;
      readonly startTime: string | null | undefined;
      readonly thumbnail: string | null | undefined;
      readonly title: string;
    }>;
  };
};
export type VideoEditModeSearchQuery = {
  response: VideoEditModeSearchQuery$data;
  variables: VideoEditModeSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "q"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "q"
  },
  {
    "kind": "Literal",
    "name": "writableOnly",
    "value": false
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchEvent",
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seriesTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creators",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isLive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "created",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EventSearchResults",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoEditModeSearchQuery",
    "selections": [
      {
        "alias": "events",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "searchAllEvents",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VideoEditModeSearchQuery",
    "selections": [
      {
        "alias": "events",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "searchAllEvents",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9d99a1d40a32bde9dd0a788a1cb36d1f",
    "id": null,
    "metadata": {},
    "name": "VideoEditModeSearchQuery",
    "operationKind": "query",
    "text": "query VideoEditModeSearchQuery(\n  $q: String!\n) {\n  events: searchAllEvents(query: $q, writableOnly: false) {\n    __typename\n    ... on EventSearchResults {\n      items {\n        id\n        title\n        seriesTitle\n        creators\n        thumbnail\n        isLive\n        created\n        duration\n        startTime\n        endTime\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d15b018f5ad47b546d44bfb093367688";

export default node;
