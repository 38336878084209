/**
 * @generated SignedSource<<4b7ed9d9c6720f9877d7ef7fa1f9844a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VideoEditModeBlockData$data = {
  readonly event: {
    readonly __typename: "AuthorizedEvent";
    readonly created: string;
    readonly creators: ReadonlyArray<string>;
    readonly id: string;
    readonly isLive: boolean;
    readonly series: {
      readonly title: string;
    } | null | undefined;
    readonly syncedData: {
      readonly duration: number;
      readonly endTime: string | null | undefined;
      readonly startTime: string | null | undefined;
      readonly thumbnail: string | null | undefined;
    } | null | undefined;
    readonly title: string;
  } | {
    readonly __typename: "NotAllowed";
    readonly dummy: boolean | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly showLink: boolean;
  readonly showTitle: boolean;
  readonly " $fragmentType": "VideoEditModeBlockData";
};
export type VideoEditModeBlockData$key = {
  readonly " $data"?: VideoEditModeBlockData$data;
  readonly " $fragmentSpreads": FragmentRefs<"VideoEditModeBlockData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VideoEditModeBlockData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "event",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dummy",
              "storageKey": null
            }
          ],
          "type": "NotAllowed",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Series",
              "kind": "LinkedField",
              "name": "series",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isLive",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creators",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SyncedEventData",
              "kind": "LinkedField",
              "name": "syncedData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "thumbnail",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "duration",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endTime",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "AuthorizedEvent",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showLink",
      "storageKey": null
    }
  ],
  "type": "VideoBlock",
  "abstractKey": null
};
})();

(node as any).hash = "666a91c09e7e1600a652f3e7f448ec00";

export default node;
